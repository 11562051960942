import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import i18n from "../../i18n";

import { getOrder, resetOrder } from "./orderSlice";
import { resetMenuItems } from "./menuItemsSlice";
import { resetRestaurants } from "./restaurantsSlice";
import { setSelectedOrderMethod } from "./showModalSlice";

const url = process.env.REACT_APP_API;

const user = JSON.parse(localStorage.getItem("user"))
  ? JSON.parse(localStorage.getItem("user"))
  : "";

const initialState = {
  fullName: "",
  firstName: "",
  lastName: "",
  email: "",
  id: "",
  phone: "",
  countryCode: "358",
  password: "",
  avatar: "",
  message: "",
  statusOk: null,
  operationName: "",
  toastActive: false,
  loggedIn: false,
  isLoading: false,
  automaticLogging: true,
  currentResetPasswordStep: "email",
  sendEmailStatus: "idle",
  checkCodeStatus: "idle",
  resetPasswordStatus: "idle",
  isSuccess: false,
  emailForResetPassword: "",
  verificationCode: null,
  otpCode: null,
  sendOtpStatus: "idle",
  checkOtpStatus: "idle",
  tempUserData: {},
  registerStatus: "idle",
  otpForResetStatus: "idle",
  resetPhone: {},
  resetPasswordOtpStatus: "idle",
  logoutStatus: "idle",
};

export const sendOtp = createAsyncThunk(
  "signIn/sendOtp",
  async (accountData, { getState, rejectWithValue }) => {
    const data = {
      name: accountData.fullName,
      email: accountData.email,
      phone: accountData.phone,
      country_code: accountData.countryCode,
      password: accountData.password,
    };

    const language = getState().lang.lang;
    const phoneNumber = "+" + data?.country_code + data?.phone;

    try {
      const response = await axios.post(
        process.env.REACT_APP_API + "sendOtp",
        { phone_number: phoneNumber },
        {
          headers: {
            "X-localization": language,
          },
        }
      );
      toast.success(i18n.t("The OTP code sent to your phone"));
      return {
        accountData: accountData,
        response: response.data,
      };
    } catch (error) {
      toast.success(
        i18n.t("The OTP code doesn't send to your phone, please try again")
      );
      return rejectWithValue(error.response.data);
    }
  }
);

export const sendOtpToReset = createAsyncThunk(
  "signIn/sendOtpToReset",
  async (params, { getState, rejectWithValue }) => {
    const language = getState().lang.lang;
    try {
      const response = await axios.post(
        process.env.REACT_APP_API + "sendOtp",
        { phone_number: "+" + params?.countryCode + params?.phone },
        {
          headers: {
            "X-localization": language,
          },
        }
      );
      toast.success(i18n.t("The OTP code sent to your phone"));
      return {
        response: response.data,
        params: params,
      };
    } catch (error) {
      toast.success(
        i18n.t("The OTP code doesn't send to your phone, please try again")
      );
      return rejectWithValue(error.response.data);
    }
  }
);

export const resetPasswordOtp = createAsyncThunk(
  "signIn/resetPasswordOtp",
  async (params, { getState, rejectWithValue }) => {
    const language = getState().lang.lang;
    try {
      const response = await axios.post(
        process.env.REACT_APP_API + "resetPassOtp",
        params,
        {
          headers: {
            "X-localization": language,
          },
        }
      );
      toast.success(i18n.t("The password has been reset successfully"));
      return {
        response: response.data,
        params: params,
      };
    } catch (error) {
      toast.error(i18n.t("The password reset failed, please try again"));
      return rejectWithValue(error.response.data);
    }
  }
);

export const checkOtp = createAsyncThunk(
  "signIn/checkOtp",
  async ({ code }, { getState, rejectWithValue, dispatch }) => {
    const language = getState().lang.lang;

    const phoneNumber =
      "+" +
      getState().signIn.tempUserData?.countryCode +
      getState().signIn.tempUserData?.phone;

    try {
      const response = await axios.post(
        process.env.REACT_APP_API + "checkOtp",
        {
          phone_number: phoneNumber,
          code: code,
        },
        {
          headers: {
            "X-localization": language,
          },
        }
      );

      await dispatch(createAccount(getState().signIn.tempUserData));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createAccount = createAsyncThunk(
  "signIn/createAccount",
  async (accountData, { getState, rejectWithValue }) => {
    const data = {
      name: accountData.fullName,
      email: accountData.email,
      phone: accountData.phone,
      country_code: accountData.countryCode,
      password: accountData.password,
    };

    const language = getState().lang.lang;
    try {
      const response = await axios.post(
        process.env.REACT_APP_API + "account/register",
        data,
        {
          headers: {
            "X-localization": language,
          },
        }
      );
      return {
        accountData: accountData,
        response: response.data,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const loginToAccount = createAsyncThunk(
  "signIn/loginToAccount",
  async (loginData, { getState, dispatch, rejectWithValue }) => {
    const language = getState().lang.lang;
    try {
      const response = await axios.post(
        process.env.REACT_APP_API + "account/login",
        loginData,
        {
          headers: {
            "X-localization": language,
          },
        }
      );
      if (response.status === 200) {
        dispatch(getOrder());
      }

      return {
        loginData: loginData,
        response: response.data,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const automaticLogin = createAsyncThunk(
  "signIn/automaticLogin",
  async (_, { getState, dispatch }) => {
    const user = JSON.parse(localStorage.getItem("user"))
      ? JSON.parse(localStorage.getItem("user"))
      : "";
    const phone = user?.phone ? user?.phone : "";
    const countryCode = user?.phone ? user?.countryCode : "358";

    if (user !== null) {
      const today = new Date();
      const day = new Date(user?.day);
      if (today.getDate() - day.getDate() < 7) {
        const language = getState().lang.lang;
        const response = await axios.post(
          process.env.REACT_APP_API + "account/rememberMe",
          null,
          {
            headers: {
              Authorization: `Bearer ${user?.token}`,
              "X-localization": language,
            },
          }
        );

        if (response.status === 200) {
          dispatch(getOrder());
        }
        return {
          user: { ...user, phone, countryCode },
          response: response.data,
        };
      } else {
        localStorage.removeItem("user");
      }
    }
  }
);

export const logoutFromAccount = createAsyncThunk(
  "signIn/logoutFromAccount",
  async (_, { getState, dispatch, rejectWithValue }) => {
    const language = getState().lang.lang;
    const user = JSON.parse(localStorage.getItem("user"));

    try {
      const response = await axios.post(
        process.env.REACT_APP_API + "account/logout",
        null,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "X-localization": language,
          },
        }
      );

      if (response.status === 200) {
        dispatch(setSelectedOrderMethod(""));
        dispatch(resetOrder());
        dispatch(resetMenuItems());
        dispatch(resetRestaurants());
        localStorage.removeItem("beforeCoupon");
        localStorage.removeItem("couponApplied");
        localStorage.removeItem("orderType");
        localStorage.removeItem("restaurantLocation");
        localStorage.removeItem("selectedRestaurantId");
        localStorage.removeItem("userAddress");
      }

      return {
        user: user,
        response: response.data,
      };
    } catch (error) {
      return rejectWithValue(error.response.message);
    }
  }
);

export const socialLogin = createAsyncThunk(
  "signIn/socialLogin",
  async (data, { getState, dispatch, rejectWithValue }) => {
    const language = getState().lang.lang;
    const dataProcessed = {
      provider_name: data?.providerId.split(".")[0],
      provider_user_id: data?.uid,
    };

    try {
      const response = await axios.post(
        process.env.REACT_APP_API + "account/loginSocial",
        dataProcessed,
        {
          headers: {
            "X-localization": language,
          },
        }
      );

      if (response.status === 200) {
        dispatch(getOrder());
      }

      return {
        data: data,
        response: response?.data,
      };
    } catch (error) {
      return rejectWithValue(error.response.message);
    }
  }
);

export const socialRegister = createAsyncThunk(
  "signIn/socialRegister",
  async (data, { getState, rejectWithValue }) => {
    const language = getState().lang.lang;
    const dataProcessed = {
      name: data?.displayName,
      email: data?.email,
      phone: data?.phoneNumber,
      country_code: data?.countryCode,
      avatar: data?.photoURL,
      password: data?.password,
      provider_name: data?.providerId.split(".")[0],
      provider_user_id: data?.uid,
    };

    try {
      const response = await axios.post(
        process.env.REACT_APP_API + "account/registerSocial",
        dataProcessed,
        {
          headers: {
            "X-localization": language,
          },
        }
      );
      return {
        data: data,
        response: response.data,
      };
    } catch (error) {
      return rejectWithValue(error.response.message);
    }
  }
);

const serverError = () => {
  toast.error(i18n.t("Something went wrong, please try again"));
};

// updateProfile
export const updateProfile = createAsyncThunk(
  "signIn/updateProfile",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_API + "account/update",
        params,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      toast.success(data.data);

      return {
        params: params,
        data: data.data,
      };
    } catch (error) {
      toast.error(error.response.data.message[0]);
      return rejectWithValue(error.message);
    }
  }
);

// SendEmailForVerification
export const SendEmailForVerification = createAsyncThunk(
  "signIn/SendEmailForVerification",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        url + "api/sendVerificationCodeResetPass",
        params
      );
      toast.success(i18n.t("The code has been sent to your email"));
      return { params: params, data: data.data };
    } catch (error) {
      toast.error(i18n.t("Something went wrong, please try again"));
      return rejectWithValue(error.message);
    }
  }
);

// checkVerificationCode
export const checkVerificationCode = createAsyncThunk(
  "signIn/checkVerificationCode",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        url + "api/sendVerificationCodeResetPass",
        params
      );
      toast.success(i18n.t("The code has been verified successfully"));
      return { params: params, data: data.data };
    } catch (error) {
      toast.error(i18n.t("Something went wrong, please try again"));
      return rejectWithValue(error.message);
    }
  }
);

// resetPassword
export const resetPassword = createAsyncThunk(
  "signIn/resetPassword",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        url + "api/resetPasswordViaEmail",
        params
      );
      toast.success(i18n.t("The password has been reset successfully"));
      return data.data;
    } catch (error) {
      toast.error(i18n.t("Something went wrong, please try again"));
      return rejectWithValue(error.message);
    }
  }
);

export const signInSlice = createSlice({
  name: "signIn",
  initialState,
  reducers: {
    reset: (state) => {
      state.message = "";
      state.statusOk = null;
      state.isLoading = false;
      state.operationName = "";
      state.toastActive = false;
    },
    resetAfterForgetPassword: (state) => {
      state.currentResetPasswordStep = "email";
      state.sendEmailStatus = "idle";
      state.checkCodeStatus = "idle";
      state.resetPasswordStatus = "idle";
      state.isSuccess = false;
      state.emailForResetPassword = "";
      state.verificationCode = null;
    },

    resetAfterSendOtp: (state) => {
      state.sendOtpStatus = "idle";
      state.checkOtpStatus = "idle";
      state.tempUserData = {};
      state.registerStatus = "idle";
    },

    resetAfterResetPasswordByPhone: (state) => {
      state.otpForResetStatus = "idle";
      state.resetPasswordOtpStatus = "idle";
      state.resetPhone = "";
    },

    activateToast: (state) => {
      state.toastActive = true;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(createAccount.fulfilled, (state, action) => {
        if (action.payload.response.status) {
          state.fullName = action.payload.accountData.fullName;
          [state.firstName, state.lastName] = state.fullName.split(" ");
          state.email = action.payload.accountData.email;
          state.id = action.payload?.response?.data?.User?.id;
          state.phone = action.payload.accountData.phone;
          state.countryCode = action.payload.accountData.countryCode;
          const today = new Date();
          const token = action.payload.response.data.token;
          localStorage.setItem(
            "user",
            JSON.stringify({
              name: state.fullName,
              id: state.id,
              email: state.email,
              phone: state.phone,
              countryCode: state.countryCode,
              avatar: state.avatar,
              token: token,
              day: today,
            })
          );
          state.loggedIn = true;
          toast.success(i18n.t("Account created successfully"));
        }
        state.registerStatus = "succeeded";
        state.isLoading = false;
      })
      .addCase(createAccount.rejected, (state, action) => {
        if (action.payload.code !== 500) {
          const responseMessage = action.payload.message;
          if (responseMessage.length === 2) {
            toast.error(i18n.t("Email and phone are already taken"));
          } else {
            toast.error(i18n.t(responseMessage[0].replace(".", "")));
          }
        } else {
          serverError();
        }
        state.isLoading = false;
      })
      .addCase(createAccount.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(loginToAccount.fulfilled, (state, action) => {
        if (action.payload) {
          state.fullName = action.payload?.response?.data?.User?.name;
          state.email = action.payload?.response?.data?.User?.email;
          state.id = action.payload?.response?.data?.User?.id;
          state.phone = action.payload?.response?.data?.User?.phone;
          state.countryCode =
            action.payload?.response?.data?.User?.country_code;
          state.avatar = action.payload?.response?.data?.User?.avatar;
          const today = new Date();
          const token = action.payload?.response?.data?.token;
          localStorage.setItem(
            "user",
            JSON.stringify({
              name: state.fullName,
              id: state.id,
              email: state.email,
              phone: state.phone,
              countryCode: state.countryCode,
              avatar: state.avatar,
              token: token,
              day: today,
            })
          );
          state.loggedIn = true;
          toast.success(i18n.t("You have logged in successfully"));
        }
        state.isLoading = false;
      })
      .addCase(loginToAccount.rejected, (state, action) => {
        if (action.payload.code !== 500) {
          toast.error(i18n.t("Incorrect credentials"));
        } else {
          serverError();
        }
        state.isLoading = false;
      })
      .addCase(loginToAccount.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(automaticLogin.fulfilled, (state, action) => {
        // here check for the status of the user's account and set data accordingly;
        if (action.payload && action.payload?.response.status) {
          state.fullName = action.payload.user.name;
          [state.firstName, state.lastName] = state.fullName.split(" ");
          state.email = action.payload.user.email;
          state.id = action.payload.user.id;
          state.phone = action.payload.user.phone;
          state.countryCode = action.payload.user.countryCode;
          state.avatar = action.payload.user.avatar;
          state.loggedIn = true;
          state.automaticLogging = false;
        }
        state.isLoading = false;
      })
      .addCase(automaticLogin.rejected, (state, action) => {
        // if (action.payload.code !== 500) {
        //     toast.warning(i18n.t('Your session has expired. Please log in'));
        // } else {
        //     serverError();
        // }
        state.isLoading = false;
        state.automaticLogging = false;
      })
      .addCase(automaticLogin.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(logoutFromAccount.fulfilled, (state, action) => {
        // here send token and checkout for response;
        if (action.payload.response.status) {
          state.loggedIn = false;
          state.fullName = "";
          state.firstName = state.lastName = "";
          state.email = "";
          state.password = "";
          state.phone = "";
          state.countryCode = "+358";
          state.avatar = "";
          localStorage.removeItem("user");
          toast.success(i18n.t("You have logged out successfully"));
          state.logoutStatus = "succeeded";
        }
      })
      .addCase(logoutFromAccount.rejected, (state, action) => {
        serverError();
        state.logoutStatus = "rejected";
      })

      .addCase(socialLogin.fulfilled, (state, action) => {
        state.operationName = "social";
        if (action.payload.response.status) {
          state.statusOk = true;
          state.message = "You have logged in successfully";
          state.fullName = action.payload.response.data.user.name;
          [state.firstName, state.lastName] = state.fullName.split(" ");
          state.email = action.payload.response.data.user.email;
          state.phone = action.payload.response.data.user.phone;
          state.countryCode = action.payload.response.data.user.country_code;
          state.avatar = action.payload.data.photoURL;
          const today = new Date();
          const token = action.payload.response.data.token;
          localStorage.setItem(
            "user",
            JSON.stringify({
              name: state.fullName,
              email: state.email,
              phone: state.phone,
              countryCode: state.countryCode,
              avatar: state.avatar,
              token: token,
              day: today,
            })
          );
          state.loggedIn = true;
        }
        state.isLoading = false;
      })
      .addCase(socialLogin.rejected, (state, action) => {
        serverError();
        state.isLoading = false;
      })
      .addCase(socialLogin.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(socialRegister.fulfilled, (state, action) => {
        state.operationName = "social";
        if (action.payload.response.status) {
          state.message = "Account created successfully";
          state.statusOk = true;
          state.fullName = action.payload.response.data.user.name;
          [state.firstName, state.lastName] = state.fullName.split(" ");
          state.email = action.payload.response.data.user.email;
          state.phone = action.payload.response.data.user.phone;
          state.countryCode = action.payload.response.data.user.country_code;
          state.avatar = action.payload.data.photoURL;
          const today = new Date();
          const token = action.payload.response.data.token;
          localStorage.setItem(
            "user",
            JSON.stringify({
              name: state.fullName,
              email: state.email,
              phone: state.phone,
              countryCode: state.countryCode,
              avatar: state.avatar,
              token: token,
              day: today,
            })
          );
          state.loggedIn = true;
          toast.success(state.message);
        }
        state.isLoading = false;
      })

      .addCase(socialRegister.rejected, (state, action) => {
        serverError();
        state.isLoading = false;
      })

      .addCase(socialRegister.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateProfile.pending, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.fullName = action.payload.params.name;
        state.email = action.payload.params.email;
        state.phone = action.payload.params.phone;
        state.countryCode = action.payload.params.countryCode;
        const today = new Date();
        const token = user.token;
        state.isLoading = false;

        localStorage.setItem(
          "user",
          JSON.stringify({
            name: state.fullName,
            email: state.email,
            phone: state.phone,
            countryCode: state.countryCode,
            token: token,
            day: today,
          })
        );
      }) // Reset Password
      .addCase(SendEmailForVerification.fulfilled, (state, action) => {
        state.sendEmailStatus = "succeeded";
        state.emailForResetPassword = action.payload.params.email;
        state.currentResetPasswordStep = "code";
      })
      .addCase(checkVerificationCode.fulfilled, (state, action) => {
        state.checkCodeStatus = "succeeded";
        state.verificationCode = action.payload.params.code;
        state.currentResetPasswordStep = "password";
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.resetPasswordStatus = "succeeded";
        state.currentResetPasswordStep = "email";
        state.emailForResetPassword = "";
        state.verificationCode = "";
      })
      .addCase(SendEmailForVerification.pending, (state, action) => {
        state.sendEmailStatus = "loading";
      })
      .addCase(checkVerificationCode.pending, (state, action) => {
        state.checkCodeStatus = "loading";
      })
      .addCase(resetPassword.pending, (state, action) => {
        state.resetPasswordStatus = "loading";
      })
      .addCase(SendEmailForVerification.rejected, (state, action) => {
        state.sendEmailStatus = "rejected";
      })
      .addCase(checkVerificationCode.rejected, (state, action) => {
        state.checkCodeStatus = "rejected";
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.resetPasswordStatus = "rejected";
      }) // sendOtp
      .addCase(sendOtp.fulfilled, (state, action) => {
        state.sendOtpStatus = "succeeded";
        state.tempUserData = action.payload.accountData;
      })
      .addCase(sendOtp.pending, (state, action) => {
        state.sendOtpStatus = "loading";
      })
      .addCase(sendOtp.rejected, (state, action) => {
        state.sendOtpStatus = "rejected";
      })
      // checkOtp
      .addCase(checkOtp.fulfilled, (state, action) => {
        state.checkOtpStatus = "succeeded";
      })
      .addCase(checkOtp.pending, (state, action) => {
        state.checkOtpStatus = "loading";
      })
      .addCase(checkOtp.rejected, (state, action) => {
        state.checkOtpStatus = "rejected";
      }) // sendOtpToReset
      .addCase(sendOtpToReset.fulfilled, (state, action) => {
        state.otpForResetStatus = "succeeded";
        state.resetPhone = action.payload.params;
      })
      .addCase(sendOtpToReset.pending, (state, action) => {
        state.otpForResetStatus = "loading";
      })
      .addCase(sendOtpToReset.rejected, (state, action) => {
        state.otpForResetStatus = "rejected";
      }) // resetPasswordOtp
      .addCase(resetPasswordOtp.fulfilled, (state, action) => {
        state.resetPasswordOtpStatus = "succeeded";
      })
      .addCase(resetPasswordOtp.pending, (state, action) => {
        state.resetPasswordOtpStatus = "loading";
      })
      .addCase(resetPasswordOtp.rejected, (state, action) => {
        state.resetPasswordOtpStatus = "rejected";
      });
  },
});

export const {
  reset,
  activateToast,
  resetAfterForgetPassword,
  resetAfterSendOtp,
  resetAfterResetPasswordByPhone,
} = signInSlice.actions;

export default signInSlice.reducer;
